import React from 'react';
import RichText from '../richText';
import PropTypes from "prop-types";
import { Link } from 'gatsby';
import {TranslateResolver} from '../../utils/translateResolver';


import Img from "gatsby-image"

const Blog = ({ data, lastBlog }) => {

    return (

        <>

            <section className="c-blog c-section js-header-waypoint" data-logo="dark" data-nav="dark" >
                <div className="o-wrapper">

                    <div className="c-blog__bg u-color-white">
                        <div className="c-blog__decoration"></div>
                        <div className="c-blog__holder">

                            <h2 className="c-blog__heading c-heading-group__title">{data.primary.title1[0].text}</h2>


                            <article className="c-blog__post c-post">
                                <div className="c-post__row">
                                    <div className="c-post__col">

                                        <div className="c-post__imageholder">
                                            <Img fixed={lastBlog.imageSharp.childImageSharp.fluid }></Img>
                                            <Link to={`descubrimientos/${lastBlog._meta.uid }`} className="c-post__image u-img" tabIndex="-1" title="Leer noticias"></Link>
                                        </div>

                                    </div>
                                    <div className="c-post__col c-post__info">

                                        <h2 className="c-post__title"><Link to={`descubrimientos/${lastBlog._meta.uid }`} className="c-post__link" tabIndex="-1">{lastBlog.title[0].text}</Link></h2>

                                        <div className="c-post__description u-serif u-justify" >

                                            <RichText
                                                render={lastBlog.description}
                                            />

                                        </div>

                                        <Link to={`descubrimientos/${lastBlog._meta.uid }`} className="c-post__btn c-arrow-link">{ TranslateResolver(2) }</Link>

                                    </div>
                                </div>
                            </article>

                            <Link to={data.primary.link._meta.uid} className="c-blog__link c-link">{data.primary.link_title}</Link>

                        </div>
                    </div>


                </div>
            </section>

        </>


    );
}

Blog.propTypes = {
    data: PropTypes.object,
    lastBlog: PropTypes.object
}

export default Blog;