import React from 'react';
import PropTypes from "prop-types";
import RichText from '../richText';
import { Link } from 'gatsby';



const AboutUs = ({ data }) => {

    console.log(data);


    return (
        

        <>
            <section className="c-about u-color-white js-header-waypoint" data-logo="light" data-nav="light" >
                <div className="c-about__decoration"></div>
                <div className="c-about__wrapper o-wrapper c-section">


                    <div className="c-heading-group">
                        <h2 className="c-heading-group__kicker">{data.primary.caption[0].text}</h2>
                        <p className="c-heading-group__title"><strong>{data.primary.title1[0].text}</strong></p>

                        <div className="c-heading-group__description u-serif u-justify"><RichText render={data.primary.description} /></div>
                    
                
                    </div>


                    <div className="c-about__field c-graphic-force">
                        <div className="c-about__graphic c-graphic-force__graphic"></div>
                        <div className="c-graphic-force__field c-graphic-force__field--left"></div>
                        <div className="c-graphic-force__field c-graphic-force__field--right"></div>
                    </div>


                    {data.primary.link && <Link to={data.primary.link._meta.uid} className="c-about__link c-link">{data.primary.link_title}</Link>}


                </div>
            </section>
        </>


    );
}

AboutUs.propTypes = {
    data: PropTypes.object
}

export default AboutUs;