import React from 'react';
import PropTypes from "prop-types";
import { RichText } from 'prismic-reactjs';




const Testimonials = ({ data, list }) => {

    return (

        <>
            <section className="c-testimonials js-header-waypoint" data-logo="dark" data-nav="dark">
                <div className="c-section">

                    <div className="c-heading-group o-wrapper u-text-center">

                        <h2 className="c-heading-group__kicker">{data.primary.caption[0].text}</h2>
                        {data.primary.title1 && <p className="c-heading-group__title">{data.primary.title1[0].text}</p>}

                    </div>

                    <div id="js-testimonials-carousel" className="c-testimonials__viewport js-carousel__notInit  ">
                        <ul className="c-testimonials__list js-carousel">

                            {list.map((field, i) => (
                                <li key={i} className={`c-testimonials__item js-carousel__item ${i != 0 ? "c-testimonials__item--notInitHide" : ""}`}>

                                    <div className="c-quote">

                                        <div className="c-quote__holder">

                                            {field.node.client &&
                                                <h3 className="c-quote__client">
                                                    <img src={field.node.client.url} width={field.node.client.dimensions.width / 2} alt={field.node.client_name} title={field.node.client_name} />
                                                    <span className="u-hidden-for-seo">{field.node.client_name}</span>
                                                </h3>
                                            }

                                            {field.node.content &&
                                                <blockquote className="c-quote__text u-serif">
                                                    <span className="c-quote__icon" />
                                                    <span className="u-justify"> 
                                                        <RichText render={field.node.content}></RichText>
                                                    </span>
                                                </blockquote>
                                            }
                                            {field.node.author && <strong className="c-quote__name">{field.node.author[0].text}</strong>}
                                        </div>


                                    </div>

                                </li>

                            ))}


                        </ul>
                    </div>

                </div>
            </section>

        </>


    );
}

Testimonials.propTypes = {
    data: PropTypes.object,
    list: PropTypes.array
}

export default Testimonials;