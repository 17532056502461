import PropTypes from "prop-types";
import { Link } from 'gatsby';
import React from 'react';

import {TranslateResolver} from "../../utils/translateResolver";

import Img from "gatsby-image"



const StudyCases = ({ data, list }) => {

    return (

        <>

            <section className="c-cases u-text-center js-header-waypoint" data-logo="dark" data-nav="dark">
                <div className="c-cases__decoration"></div>
                <div className="c-cases__wrapper o-wrapper c-section">


                    <div className="c-heading-group">
                        <div className="c-heading-group__kicker">{data.primary.caption1[0].text}</div>
                        <h2 className="c-heading-group__title">{data.primary.title1[0].text}</h2>
                    </div>


                    <div id="js-cases-carousel" className="c-cases__viewport js-carousel__notInit">
                        <ul className="c-cases__list js-carousel ">

                            {list.map((field, i) => (
                                <li key={i} className={`c-cases__item js-carousel__item ${i != 0 ? "c-cases__item--notInitHide" : ""}`}>

                                    <div className="c-cases__case c-case-preview">

                                        {field.node.image_preview && <Link to={`casos-de-exito/${field.node._meta.uid}`} className="c-case-preview__image u-img" tabIndex="-1" title={field.node.title[0].text}>

                                        <Img fluid={field.node.image_previewSharp.childImageSharp.fluid} />

                                        </Link>}
                                        <div className="c-case-preview__info-holder">
                                            <Link to={`casos-de-exito/${field.node._meta.uid}`} className="c-case-preview__link" title={ TranslateResolver(7) } ></Link>
                                            <div className="c-case-preview__info">
                                                <div className="c-case-preview__translate">
                                                    <h3 className="c-case-preview__title">{field.node.title ? field.node.title[0].text : ""}</h3>
                                                    <p className="c-case-preview__text u-serif">{field.node.client_name ? field.node.client_name[0].text : ""} {field.node.client_name[0].text != "" && field.node.description[0].text != ""  ?  ":" : ""  } {field.node.description ? field.node.description[0].text : ""}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </li>

                            ))}




                        </ul>
                    </div>


                    <Link to={data.primary.link._meta.uid} className="c-cases__link c-link">{data.primary.link_title}</Link>


                </div>
            </section>

        </>


    );
}

StudyCases.propTypes = {
    data: PropTypes.object,
    list: PropTypes.array
}

export default StudyCases;