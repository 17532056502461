import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'gatsby';


const Hero = ({ data }) => {

    return (

        <>

            <div className="c-home-hero o-align-middle js-header-waypoint" data-logo="dark" data-nav="light">
                <div className="o-align-middle__aligner">
                    <div className="c-home-hero__wrapper o-wrapper c-section">
                        <div className="c-home-hero__holder">

                            <h2 className="c-home-hero__title">{data.primary.title[0].text}</h2>
                            <p className="c-home-hero__text">{data.primary.description[0].text}</p>

                            <div className="c-home-hero__btns">
                                {data.primary.anchor_link &&
                                    <Link to={data.primary.anchor_link} className="c-home-hero__btn c-rounded-btn js-show-contact" ><span>{data.primary.anchor_link_text}</span></Link>
                                }
                                {data.primary.second_link &&
                                    <Link to={data.primary.second_link._meta.uid} className="c-home-hero__btn c-rounded-btn c-rounded-btn--border"><span>{data.primary.second_link_text}</span></Link>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className="c-home-hero__decoration"></div>
            </div>


        </>


    );
}

Hero.propTypes = {
    data: PropTypes.object
}

export default Hero;